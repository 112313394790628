const routerViewMap = {
  Home: () => import('@/views/Home/HomeIndex'),
  Dashboard: () => import('@/views/Home/Dashboard/Dashboard'),
  /*  */
  Equipment: () => import('@/views/Equipment/EquipmentIndex'),
  /*  */
  System: () => import('@/views/System/SystemIndex'),
  User: () => import('@/views/System/User/User'),
  Menu: () => import('@/views/System/Menu/Menu'),
  Role: () => import('@/views/System/Role/Role'),
  Dept: () => import('@/views/System/dept/index'),
  /* 日志 */
  Log: () => import('@/views/Log/LogIndex'),
  LoginInfor: () => import('@/views/Log/logininfor/LoginInfor'),
  OperLog: () => import('@/views/Log/OperLog/OperLog'),
  /* 管理端 */
  Factory: () => import('@/views/Manage/ManageIndex'),
  FactoryList: () => import('@/views/Manage/Factory/FactoryList'),
  FactoryAdd: () => import('@/views/Manage/Factory/FactoryAdd'),
  Agency: () => import('@/views/agency/AgencyIndex'),
  AgencyList: () => import('@/views/agency/agency/AgencyList'),
  AgencyDetails: () => import('@/views/agency/agency/AgencyDetails'),
  Finance: () => import('@/views/Finance/Finance'),
  PaymentRecords: () => import('@/views/Finance/PaymentRecords/PaymentRecords'),
  Custom: () => import('@/views/Custom/Custom'),
  CustomList: () => import('@/views/Custom/CustomList/CustomList'),
  Unsettled: () => import('@/views/Custom/Unsettled/Unsettled'),
  Transacted: () => import('@/views/Custom/Transacted/Transacted'),
  CustomDetail: () => import('@/views/Custom/CustomDetail/CustomDetail'),
  ExpiringCustomer: () => import('@/views/Custom/ExpiringCustomer/ExpiringCustomer'),
  MonthlySharing: () => import('@/views/Finance/MonthlySharing/MonthlySharing'),
  ReturnRecords: () => import('@/views/Finance/ReturnRecords/ReturnRecords'),
  PayRecords: () => import('@/views/Finance/PayRecords/PayRecords'),
  UpdateLog: () => import('@/views/UpdateLog/UpdatelogIndex'),
  UpdateLogDetails: () => import('@/views/UpdateLog/UpdateLogDetails/UpdateLogDetails'),
  UserInfo: () => import('@/views/System/User/UserInfo'),
  EditUserInfo: () => import('@/views/System/User/EditUserInfo'),
  EditUserPass: () => import('@/views/System/User/EditUserPass'),
  textMessage: () => import('@/views/TextMessage/TextMessage'),
  replyRecord: () => import('@/views/TextMessage/ReplyRecord/ReplyRecord'), //短信黑名单列表
  SMSrecords: () => import('@/views/TextMessage/SMSrecords/SMSrecords'), //新建发送任务
  sendingSMS: () => import('@/views/TextMessage/SendingSMS/SendingSMS'), //任务发送列表
  messageDetail: () => import('@/views/TextMessage/SendingSMS/MessageDetail'), //任务发送列表详情
  // messageModel: () => import('@/views/TextMessage/MessageModel/MessageModel'),
  // messageSignature: () => import('@/views/TextMessage/MessageSignature/MessageSignature'),
  /* 工位端 */
  UserClient: () => import('@/views/Client/System/ClientIndex'),
  UserClientList: () => import('@/views/Client/System/User/UserClientList'),
  ClientSystem: () => import('@/views/Client/System/ClientIndex'),
  ClientSystemDept: () => import('@/views/Client/System/Dept/Dept'),
  ClientBasic: () => import('@/views/Client/Basic/BasicIndex'),
  ClientBasicCompany: () => import('@/views/Client/Basic/Company/Company'),
  ClientBasicEquipment: () => import('@/views/Client/Basic/Equipment/Equipment'),
  ClientBasicProduct: () => import('@/views/Client/Basic/Product/Product'),
  ClientBasicRejects: () => import('@/views/Client/Basic/Rejects/Rejects'),
  ClientBasicWorkmanship: () => import('@/views/Client/Basic/Workmanship/Workmanship'),
  ClientBasicSalary: () => import('@/views/Client/Basic/Salary/Salary'),
  ClientProduce: () => import('@/views/Client/Produce/ProduceIndex'),
  ClientOrderList: () => import('@/views/Client/Produce/OrderList/OrderList'),
  ClientWorkOrder: () => import('@/views/Client/Produce/WorkOrder/WorkOrder'),
  ClientProgress: () => import('@/views/Client/Produce/Progress/Progress'),
  ClientWorkReport: () => import('@/views/Client/Produce/WorkReport/WorkReport'),
  ClientHome: () => import('@/views/Client/Home/HomeIndex'),
  ClientDashboard: () => import('@/views/Client/Home/Dashboard/Dashboard'),
  ClientFactoryDetail: () => import('@/views/Client/System/factory/FactoryDetail'),
  ClientAuthentication: () => import('@/views/Client/System/factory/Authentication'),
  ClientStorage: () => import('@/views/Client/Storage/StorageIndex'),
  ClientLoginStorage: () => import('@/views/Client/Storage/InStock/InStock'),
  ClientLogoutStorage: () => import('@/views/Client/Storage/OutStock/OutStock'),
  // ClientLoginStorage: () => import('@/views/Client/Storage/LoginStorage/LoginStorage'),
  // ClientLogoutStorage: () => import('@/views/Client/Storage/OutStorage/OutStorage'),
  ClientStorageDetail: () => import('@/views/Client/Storage/StorageDetail/StorageDetail'),
  ClientStockList: () => import('@/views/Client/Storage/StockList/StockList'),
  ClientGanttChart: () => import('@/views/Client/Home/GanttChart/GanttChart'),
  ClientBulletinBoard: () => import('@/views/Client/Home/BulletinBoard/BulletinBoard'),
  ClientProductionManagement: () => import('@/views/Client/Produce/WorkHourStatistics/WorkHourStatistics'),
  ClientOrderDetails: () => import('@/views/Client/Produce/OrderList/OrderDetails'),
  ClientWorkOrderDetails: () => import('@/views/Client/Produce/WorkOrder/WorkOrderDetails'),
  notice:() => import('@/views/Client/Home/NoticeList/NoticeList'),
  InformationDetails: () => import('@/views/Client/Produce/Progress/ProgressDetails'),
  outputDetails: () => import('@/views/Client/Storage/Outputdetails/Outputdetails'),
  inputDetails: () => import('@/views/Client/Storage/InputDetails/InputDetails'),
  track: () => import('@/views/Client/Produce/trackList/trackList'),
  trackInformation: () => import('@/views/Client/Produce/trackList/trackDetails'),

  /* 代理商端 */
  AgentSystem: () => import('@/views/Agent/System/System'),
  AgentSystemDept: () => import('@/views/Agent/System/Dept/Dept'),
  AgentSystemUser: () => import('@/views/Agent/System/User/User'),
  AgentSystemSetup: () => import('@/views/Agent/System/Setup/Setup'),
  AgentFactory: () => import('@/views/Agent/Factory/Factory'),
  AgentFactoryList: () => import('@/views/Agent/Factory/FactoryList/FactoryList'),
  AgentHome: () => import('@/views/Agent/Home/HomeIndex'),
  AgentDashboard: () => import('@/views/Agent/Home/Dashboard/Dashboard'),
  AgentUnsettled: () => import('@/views/Agent/Factory/Unsettled/Unsettled'),
  AgentTransacted: () => import('@/views/Agent/Factory/Transacted/Transacted'),
  AgentFactoryDetail: () => import('@/views/Agent/Factory/FactoryDetail/FactoryDetail'),
  AgentExpiringCustomer: () => import('@/views/Agent/Factory/ExpiringCustomer/ExpiringCustomer'),
  AgentFinance: () => import('@/views/Agent/Finance/Finance'),
  AgentPaymentRecords: () => import('@/views/Agent/Finance/PaymentRecords/PaymentRecords'),
  AgentMonthlySharing: () => import('@/views/Agent/Finance/MonthlySharing/MonthlySharing'),
  AgentReturnRecords: () => import('@/views/Agent/Finance/ReturnRecords/ReturnRecords'),
  AgentPayRecords: () => import('@/views/Agent/Finance/PayRecords/PayRecords'),
  AgentOperlog: () => import('@/views/Agent/System/Operlog/Operlog'),
  AgentSystemUserInfo: () => import('@/views/Agent/System/User/UserInfo'),
  AgentSystemEditUserInfo: () => import('@/views/Agent/System/User/EditUserInfo'),
  AgentSystemEditUserPass: () => import('@/views/Agent/System/User/EditUserPass')
}
export default (key) => {
  return routerViewMap[key] || null
}
