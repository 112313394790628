import store from '@/store'

export const hasPermi = {
  mounted (el, binding, vnode) {
    const { value } = binding
    const permissions = store.state.permissions.permissions
    if (permissions && permissions.indexOf(value) < 0) {
      // el.style.display = 'none'
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}
