import axios from 'axios'
import Cookies from 'js-cookie'
import { ElMessage, ElLoading } from 'element-plus'
import router from '../router/index'

let toast = null // loading加载。。。
let count = 0 // 请求计数器
let timeId = null
const delayLoading = 200

/* 开启loading 200ms之内返回数据不开启 loading */
function setDelayLoading () {
  clearTimeout(timeId)
  timeId = setTimeout(() => {
    toast = ElLoading.service({
      lock: true,
      fullscreen: true,
      text: '加载中...',
      background: 'transparent'
    })
    clearTimeout(timeId)
  }, delayLoading)
}

/* clear loading */
function clearLoading () {
  clearTimeout(timeId)
  if (toast) {
    toast.close()
  }
}

axios.interceptors.request.use(function (config) {
  //config.baseURL = ''
  config.timeout = 50000 // 请求超时
  /* 请求加1 */
  setDelayLoading()
  /* 携带Token */
  if (router.currentRoute.value.path !== '/login' || config.url === '/api/auth/getUserInfo' || config.url === '/api/auth/getPermissions') {
    config.headers.Authorization = Cookies.get('token')
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  const fileName = response.headers['content-disposition'] // res.headers['content-disposition'].match(/fushun(\S*)xls/)[0];
  // 如果有下载文件头部 返回带headers的数据
  if (typeof fileName !== 'undefined') {
    clearLoading()
    return response
  }
  /* 请求减1 */
  clearLoading()
  const {
    code,
    message
  } = response.data
  if (code !== 200) {
    ElMessage.error({
      message: message
    })
  }
  // token过期清除token
  if (code === 401) {
    Cookies.remove('token')
  }
  return response.data
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break
      case 401:
        error.message = '未授权，请重新登录'
        break
      case 403:
        error.message = '拒绝访问'
        break
      case 404:
        error.message = '请求错误,未找到该资源'
        break
      case 405:
        error.message = '请求方法未允许'
        break
      case 408:
        error.message = '请求超时'
        break
      case 500:
        error.message = '服务器端出错'
        break
      case 501:
        error.message = '网络未实现'
        break
      case 502:
        error.message = '网络错误'
        break
      case 503:
        error.message = '服务不可用'
        break
      case 504:
        error.message = '网络超时'
        break
      case 505:
        error.message = 'http版本不支持该请求'
        break
      default:
        error.message = `未知错误${error.response.status}`
    }
  } else {
    // error.messages = "连接到服务器失败";
  }

  // 发生错误关闭loading
  clearTimeout(timeId)
  if (toast) {
    toast.close()
  }

  // 取消发送请求的错误
  if (axios.isCancel(error)) {
    ElMessage.error({
      message: error.message
    })
    return
  }

  ElMessage.error({
    message: error.message
  })

  return Promise.reject(error)
})

export default axios
