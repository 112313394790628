import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import {toRaw} from 'vue'
import store from '../store/index'
import Cookies from 'js-cookie'
import getterRouters from '@/utils/getterRouters'
import {Logger} from "sass";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'layout',
    component: () => import(/* webpackChunkName: 'layout' */'@/layout/layoutIndex.vue'),
    children: [
      {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import(/* webpackChunkName: 'userInfo' */'@/views/userInfo/userInfo.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */'@/views/logIn/logIn.vue')
  },
  {
    path: '/manage/login',
    name: 'manageLogin',
    component: () => import(/* webpackChunkName: 'manageLogin' */'@/views/Agent/LogIn/Index.vue')
  },
  {
    path: '/client/login',
    name: 'clientLogin',
    component: () => import(/* webpackChunkName: 'clientLogin' */'@/views/Client/Login/Index.vue')
  },
  {
    path: '/client/LargeScreen',
    name: 'LargeScreen',
    component: () => import(/* webpackChunkName: 'LargeScreen' */'@/views/Client/Home/LargeScreen/LargeScreen')
  },
  {
    path: '/client/BulletinBoard',
    name: 'BulletinBoard',
    component: () => import(/* webpackChunkName: 'LargeScreen' */'@/views/Client/Home/BulletinBoard/BulletinBoard')
  },
  {
    path: '/client/GanttChart',
    name: 'GanttChart',
    component: () => import(/* webpackChunkName: 'LargeScreen' */'@/views/Client/Home/GanttChart/GanttChart')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
/* 放行白名单 */
const whiteList = ['/login', '/403', '/404', '/manage/login','/client/login']
// 全局前置守卫
router.beforeEach((to, from) => {
  const routersFlag = (store.state as any).permissions.routersFlag
  // to 去哪  from来路  next下一个
  const Token = Cookies.get('token') || ''
  if (Token) {
    // 已登陆 不允许进入 登录页
    if (to.path === '/login' || to.path === '/manage/login' || to.path === '/client/login') {
      return '/'
    }
    /* 默认进入挂载路由 */
    if (routersFlag) {
      const userType = Cookies.get('userType')
      if(userType === 'client'){
        if(localStorage.getItem('Factory_permissions')){
          const permissions = JSON.parse(localStorage.getItem('Factory_permissions') || '')
          const RoutersAndMenuObj = getterRouters(permissions)
          store.commit('permissions/setRouters', RoutersAndMenuObj.routers)
          store.commit('permissions/setMenu', RoutersAndMenuObj.menu)
          store.commit('permissions/setPermissions', RoutersAndMenuObj.permissions)
          // 挂载路由
          RoutersAndMenuObj.routers.forEach((item: any) => router.addRoute('layout', item))
          store.commit('permissions/setRoutersFlag', false)
          // 成功后激活
          if (to.path === '/') {
            return RoutersAndMenuObj.routers[0].path
          } else {
            return to.path
          }
        }else{
          Cookies.remove('token')
          Cookies.remove('userInfo')
          return '/client/login'
        }
      }else{
        // 判断本地是否存在路由
        if (localStorage.getItem('IOT_permissions')) {
          const permissions = JSON.parse(localStorage.getItem('IOT_permissions') || '')
          const RoutersAndMenuObj = getterRouters(permissions)
          store.commit('permissions/setRouters', RoutersAndMenuObj.routers)
          store.commit('permissions/setMenu', RoutersAndMenuObj.menu)
          store.commit('permissions/setPermissions', RoutersAndMenuObj.permissions)
          // 挂载路由
          RoutersAndMenuObj.routers.forEach((item: any) => router.addRoute('layout', item))
          store.commit('permissions/setRoutersFlag', false)
          // 成功后激活
          if (to.path === '/') {
            return RoutersAndMenuObj.routers[0].path
          } else {
            return to.path
          }
        } else {
          if(userType === 'admin'){
            Cookies.remove('token')
            Cookies.remove('userInfo')
            return '/login'
          }else{
            Cookies.remove('token')
            Cookies.remove('userInfo')
            return '/manage/login'
          }


        }
      }

    } else {
      if (to.path === '/') {
        return (store.state as any).permissions.routers[0].path
      }
      return true
    }
  } else {
    // 没有登录
    if (whiteList.indexOf(to.path) !== -1) {
      return true
    } else {
      if(Cookies.get('userType') === 'client'){
        return '/client/login'
      }else if(Cookies.get('userType') === 'manage'){
        return '/manage/login'
      }else if (Cookies.get('userType') === 'admin'){
        return '/login'
      }else{
        return '/client/login'
      }
    }
  }
})

/* 全局后置守卫 */
router.afterEach((to, from, failure) => {
  if (!failure) {
    nameArr = []
    to.matched.forEach(route => {
      nameArr.push({
        title: route.meta.title,
        path: route.path
      })
    })
  }
  nameArr.splice(0, 1)
  store.commit('permissions/setcrumbs', nameArr)
  // sendToAnalytics(to.fullPath)
})
/* 面包屑过滤 */
let nameArr = []

function defaultRedirect(toPath, flag, routers) {
  if (flag) {
    if (toPath === '/') {
      return routers.routers[0].path
    } else {
      return toPath
    }
  } else {
    return toPath
  }
}

function sendToAnalytics(to: any) {
  if (to !== '/login') {
    const Routers = toRaw((store.state as any).permissions.routers)

    const routersNameArr = to.trim().split('/').filter(item => item !== '')
    /* 先清空 面包屑 */
    nameArr = []
    /* 得到过滤后的面包屑数组 */
    const breadcrumbArr = forEachRoutersNameArr(routersNameArr, Routers)
    store.commit('permissions/setcrumbs', breadcrumbArr)
    // document.title = breadcrumbArr[breadcrumbArr.length -1].title
  }
}


function forEachRoutersNameArr(routersNameArr, allRouters) {
  allRouters.find(router => {
    let req = new RegExp(routersNameArr[nameArr.length], 'g')
    if (req.test(router.path)) {
      nameArr.push({
        title: router.meta.title,
        path: router.path
      })
      if (router.children && nameArr.length <= routersNameArr.length) {
        forEachRoutersNameArr(routersNameArr, router.children)
      }
      return true
    }
    return false
  })
  return nameArr
}

export default router
