import Cookies from 'js-cookie'

/**
 * 检查是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal (path) {
  return /^(https?:|http:|mailto:|tel:)/.test(path)
}

/**
 *  判断字符是否为汉字，
 * @param s
 * @returns {boolean}
 */
function isChinese (s) {
  return /[\u4e00-\u9fa5]/.test(s)
}

/**
 *  汉字转Unicode编码
 * @param str
 * @returns {string}
 */
export function ch2Unicdoe(str) {
  if (!str) {
    return;
  }
  let unicode = ''
  for (let i = 0; i < str.length; i++) {
    const temp = str.charAt(i)
    if (isChinese(temp)) {
      unicode += '\\u' + temp.charCodeAt(0).toString(16);
    } else {
      unicode += temp;
    }
  }
  return unicode;
}

/**
 *  Unicode编码 转汉字
 * @param str
 * @returns {string}
 */
export function unicode2Ch(str) {
  if (!str) {
    return;
  }
  // 控制循环跃迁
  let len = 1;
  let result = ""
  // 注意，这里循环变量的变化是i=i+len 了
  for (let i = 0; i < str.length; i = i + len) {
    len = 1;
    const temp = str.charAt(i)
    if (temp == "\\") {
      // 找到形如 \u 的字符序列
      if (str.charAt(i + 1) == "u") {
        // 提取从i+2开始(包括)的 四个字符
        const unicode = str.substr(i + 2, 4)
        // 以16进制为基数解析unicode字符串，得到一个10进制的数字
        result += String.fromCharCode(parseInt(unicode, 16).toString(10))
        // 提取这个unicode经过了5个字符， 去掉这5次循环
        len = 6;
      } else {
        result += temp
      }
    } else {
      result += temp
    }
  }
  return result
}

/* 设置cookie */
export function setCookie(name, value) {
  let exp = new Date()
  exp.setTime(exp.getTime() + 600 * 60 * 1000)
  document.cookie =
    name +
    "=" +
    encodeURIComponent(JSON.stringify(value)) +
    ";expires=" +
    exp.toGMTString()
}

/* 读取Cookie */
export function getCookie(name) {
  // 读取时，忽略键名大小写
  let reg = new RegExp(["(?:^| )", name, "=([^;]*)"].join(""), "i"),
    arr = document.cookie.match(reg)
  return arr ? decodeURIComponent(arr[1]) : null
}

/**
 * 为任意的一个元素，绑定任意的一个事件
 * @param element
 * @param type
 * @param fn
 */
export function addEventListener(element, type, fn) {
  if (element.addEventListener) {
    element.addEventListener(type, fn, false)
  } else if (element.attachEvent) {
    element.attachEvent("on" + type, fn)
  } else {
    element["on" + type] = fn
  }
}

/**
 * 提取带参数的url中的参数
 * @param url  url
 * @returns {{}|any}  对象的形式返回
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
}

/**
 *  时间转换
 * @param dt
 * @returns {{month: (string|number), hour: (string|number), year: number, dat: (string|number), minute: (string|number), second: (string|number)}}
 */
export function getDate (dt, type) {
  // 获取年
  const year = dt.getFullYear()
  // 获取月
  let month = dt.getMonth() + 1
  // 获取日
  let dat = dt.getDate()
  // 获取时
  let hour = dt.getHours()
  // 获取分
  let minute = dt.getMinutes()
  // 获取秒
  let second = dt.getSeconds()

  month = month < 10 ? '0' + month : month
  dat = dat < 10 ? '0' + dat : dat
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  if (!type) {
    return {
      year: year, // 年
      month: month, // 月
      dat: dat, // 日
      hour: hour, // 时
      minute: minute, // 分
      second: second // 秒
    }
  } else {
    return year + '-' + month + '-' + dat
  }
}

// 不传时间戳就是当前事件，传就是时间戳格式化之后的事件
/*
var date=getDate(new Date(1577447588670));
console.log(date); */

/**
 *  过滤富文本中的图片链接
 * @param value
 * @param imgUrl
 * @returns {*}
 */
export function addImgUrl(value, imgUrl) {
  if (value) {
    return value.replace('src="/upload', 'src="' + imgUrl + "/upload");
  } else {
    return value;
  }
}

/**
 *  时间格式转换 中文大写
 * @param sum
 * @returns {string}
 */
export function exDate(sum) {
  let date = new Date(sum)
  let year = date.getFullYear()
  let month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1
  date = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  date = "" + year + month + date + "";
  const b = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"]
  year =
    b[date.substring(0, 1)] +
    b[date.substring(1, 2)] +
    b[date.substring(2, 3)] +
    b[date.substring(3, 4)]
  month =
    date.substring(4, 6) < 10
      ? b[date.substring(4, 5)] + b[date.substring(5, 6)]
      : date.substring(4, 6) == 10
      ? "零壹拾"
      : b[date.substring(4, 5)] + "拾" + b[date.substring(5, 6)]
  date =
    date.substring(6, 8) < 10
      ? b[date.substring(6, 7)] + b[date.substring(7, 8)]
      : date.substring(6, 8) == 10 ||
        date.substring(6, 8) == 20 ||
        date.substring(6, 8) == 30
      ? "零" + b[date.substring(6, 7)] + "拾"
      : b[date.substring(6, 7)] + "拾" + b[date.substring(7, 8)]
  // return year + '年' + month + '月' + date + '日'
  return year + "年"
}

/**
 * 将毫秒的时间转换成美式英语的时间格式,eg: 2018  May 3rd
 * @param millinSeconds
 * @returns {string}
 */
export function formatDate(millinSeconds) {
  const date = new Date(millinSeconds);
  const monthArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Spt",
    "Oct",
    "Nov",
    "Dec",
  ];
  const suffix = ["st", "nd", "rd", "th"];

  const year = date.getFullYear(); // 年
  const month = monthArr[date.getMonth()]; // 月
  let ddate = date.getDate(); // 日

  if (ddate % 10 < 1 || ddate % 10 > 3) {
    ddate = ddate + suffix[3];
  } else if (ddate % 10 == 1) {
    ddate = ddate + suffix[0];
  } else if (ddate % 10 == 2) {
    ddate = ddate + suffix[1];
  } else {
    ddate = ddate + suffix[2];
  }
  // return ddate + " "+ month + " " + year;
  // 英文 月 日
  return month + " " + ddate;
}

// 字典值翻译
export function dictTranslate(dict, value) {
  let label = "";
  dict.forEach((item) => {
    if (item.value === value) {
      label = item.label;
    }
  });
  return label;
}

// 分钟转成小时，保留小数点后一位数字
export function minuteToHour(minute) {
  let hour = minute / 60;
  return hour.toFixed(1);
}
