import { createStore } from 'vuex'
import userInfo from './module/userInfo'
import permissions from './module/permissions'
export default createStore({
  state: {
    isCollapse: false, // 侧边栏展开收起
    classIfy: [
      { label: '产成品', value: 1 },
      { label: '半成品', value: 2 },
      { label: '原材料', value: 3 },
      { label: '备品备件', value: 4 }
    ] // 物料分类
  },
  getters: {
    // 物料分类
    classIfy: state => state.classIfy
  },
  mutations: {
    setIsCollapse (state) {
      state.isCollapse = !state.isCollapse
    }
  },
  actions: {},
  modules: {
    userInfo,
    permissions
  }

})
