import AsyncImport from './AsyncImport'

export default function getterRouters (Routers) {
  /* 过滤成功的路由 */
  const routers = forEachRouters(Routers)
  const menu = forEachMenu(Routers)
  const permissions = forEachPermission(Routers)
  return {
    routers,
    menu,
    permissions
  }
}

/* 过滤路由 */
function forEachRouters (routers) {
  const fmRouters = []
  routers.forEach((item, index) => {
    /* 目录 */
    if (item.type === 'M' && item.children && item.children.length > 0) {
      fmRouters.push({
        path: item.path,
        name: item.name,
        component: AsyncImport(item.name),
        redirect: item.children[0].path,
        meta: {
          title: item.title,
          icon: item.icon
        },
        children: forEachRouters(item.children)
      })
    }
    /* 菜单 */
    if (item.type === 'C' && item.children) {
      fmRouters.push({
        path: item.path,
        name: item.name,
        component: AsyncImport(item.name),
        meta: {
          title: item.title,
          icon: item.icon
        }
      })
    }
  })
  return fmRouters
}

/* 过滤菜单 */
function forEachMenu (Routers) {
  const menu = []
  Routers.forEach((item, index) => {
    /* 目录 */
    if (item.type === 'M' && item.children && item.children.length > 0 && !item.hidden) {
      menu.push({
        path: item.path,
        name: item.title,
        icon: item.icon,
        hidden: item.hidden,
        children: forEachMenu(item.children)
      })
    }
    /* 菜单 */
    if (item.type === 'C' && item.children && !item.hidden) {
      menu.push({
        path: item.path,
        name: item.title,
        icon: item.icon,
        hidden: item.hidden
      })
    }
  })
  return menu
}

/* 过滤按钮权限 */
function forEachPermission (Routers, permissions = []) {
  Routers.forEach((item, index) => {
    /* 目录 */
    if (item.type === 'M' && item.children && item.children.length > 0) {
      forEachPermission(item.children, permissions)
    }
    if (item.type === 'C') {
      if (item.permission != null) {
        permissions.push(item.permission)
      }
      forEachPermission(item.children, permissions)
    }
    if (item.type === 'F') {
      permissions.push(item.permission)
    }
  })
  return permissions
}
