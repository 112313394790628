
import { getFactoryMenu, getPermissions, getAgentMenu } from '../../api/auth/login'

export default {
  namespaced: true,
  state: () => ({
    routers: [],
    menu: [],
    crumbs: [], // 面包屑
    routersFlag: true, // 默认进入挂载路由
    permissions: [] // 按钮权限
  }),
  mutations: {
    /* 设置路由 */
    setRouters (state, data) {
      state.routers = data
    },
    /* 设置菜单 */
    setMenu (state, data) {
      state.menu = data
    },
    /* 设置面包屑 */
    setcrumbs (state, data) {
      state.crumbs = data
    },
    setRoutersFlag (state, flag) {
      state.routersFlag = flag
    },
    setPermissions (state, data) {
      state.permissions = data
    }
  },
  actions: {
    async permissions (context, payload) {
      return await getPermissions(payload)
    },
    /* 获取工厂端菜单 */
    async FactoryMenu (context, payload) {
      return await getFactoryMenu(payload)
    },
    /* 获取代理商端菜单 */
    async AgentMenu (context, payload) {
      return await getAgentMenu(payload)
    }
  }
}
