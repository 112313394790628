import { getCookie } from '@/utils/utils.js'

export default {
  namespaced: true,
  state: () => ({
    userInfo: JSON.parse(getCookie('userInfo') || '{}') || {}
  }),
  mutations: {
    SETUSERINFO (state, data) {
      state.userInfo = data
    }
  }
}
